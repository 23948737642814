import * as React from 'react';
import { Text, Box } from '@chakra-ui/react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Dl from '../components/Dl';
import Dt from '../components/Dt';
import Dd from '../components/Dd';
import { maxW } from '../assets/config';
import JsonLd from '../components/JsonLd';

const LawPage = () => {
  return (
    <Layout>
      <Seo
        title="特定商取引法に基づく表記"
        description="ChokerJokerの特定商取引法について説明します。"
      />
      <JsonLd
        title="特定商取引法に基づく表記"
        description="ChokerJokerの特定商取引法について説明します。"
      />
      <Box maxW={maxW} mx="auto">
        <Text
          as="h2"
          py={4}
          my={8}
          fontSize={{ base: '2xl', md: '4xl', xl: '6xl' }}
          fontWeight="bold"
          borderBottom="2px solid black"
        >
          特定商取引法に基づく表記
        </Text>
        <Dl>
          <Dt>事業者の名称</Dt>
          <Dd>ジョンワークス合同会社</Dd>
          <Dt>代表責任者</Dt>
          <Dd>松木隆弥</Dd>
          <Dt>所在地</Dt>
          <Dd>350-0822 埼玉県川越市山田1600-4</Dd>
          <Dt>電話番号</Dt>
          <Dd>
            0493-24-5067
            ※お電話でのお問い合わせは受け付けておりません。予めご了承下さい。
          </Dd>
          <Dt>メールアドレス</Dt>
          <Dd>
            <a href="mailto:info@chokerjoker.com">
              <u>info@chokerjoker.com</u>
            </a>{' '}
            ※お問合せはこちらになります。
          </Dd>
          <Dt>サイトURL</Dt>
          <Dd>
            <a href="https://chokerjoker.com">
              <u>https://chokerjoker.com</u>
            </a>
          </Dd>
          <Dt>販売価格</Dt>
          <Dd>
            各商品の紹介ページに記載している価格（表示価格/消費税込み）とします。
          </Dd>
          <Dt>商品代金以外に必要な料金</Dt>
          <Dd>
            ・消費税<br />
            ・送料:全国一律370円 ※1回のご購入5,000円以上にて送料無料となります。<br />
            ・コンビニ決済の場合 ※手数料190円
          </Dd>
          <Dt>引き渡し時期</Dt>
          <Dd>
          お支払いから3営業日以内に発送します。
          </Dd>
          <Dt>お支払方法並びにお支払いの時期</Dt>
          <Dd>
            ・クレジットカード決済：商品注文時
            <br />
            ・コンビニ支払：商品注文後、一週間以内
            <br/>
            ・Amazon Pay：商品注文時
          </Dd>
          <Dt>返品・交換・キャンセルにつきまして</Dt>
          <Dd>
            商品発送後の返品・交換・キャンセルには対応しておりません。
            商品に欠陥がある場合のみ交換が可能です、お手数ですがご連絡ください。
          </Dd>
          <Dt>返品期限</Dt>
          <Dd>
            商品到着から7日以内にご連絡ください。
            7日以上経過した場合、再送・返金の対応はできません。予めご了承ください。
          </Dd>
          <Dt>返品送料</Dt>
          <Dd>
            商品に欠陥がある場合、弊社で負担いたします。
            それ以外につきましては、お客様のご負担とさせて頂きます。
          </Dd>
        </Dl>
      </Box>
    </Layout>
  );
};

export default LawPage;
